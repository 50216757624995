import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Seo from '../components/Seo'
import SubLayout from '../components/layouts/SubLayout'
import StyledGeneralPage from '../components/styles/StyledGeneralPage'

const OurCampusPage = ({ data }) => {
  const galleries = data.allSanityGallery.nodes

  return (
    <SubLayout>
      <Seo title='Our Campus' pathname='/our-campus' />
      <StyledOurCampusPage>
        <h1>Our Campus</h1>

        <p className="breadcrumbs">
        <Link to='/'>Home</Link> {`>`}&nbsp;
        Our Campus
      </p>


        <p>Visitors to the campus constantly remark on the welcome they receive from staff and the relaxed feel of the campus. Students find themselves in an environment that is conducive to a great learning experience. Our smaller classes also allow for more personal attention from lecturers and support staff.</p>

        <h2>Our Facilities</h2>
        <ul>
          <li>Air-conditioned lecture rooms</li>
          <li>Computer studios with flat screens, swivel chairs and data projector</li>
          <li>A computer workshop where learners can practice their technical PC skills</li>
          <li>A resource centre with PCs with free internet access and printing facilities</li>
          <li>A library of reference material that learners can access</li>
          <li>Free Wi-Fi available on campus to all learners</li>
          <li>A large canteen and outdoor student area</li>
          <li>Full-time security personnel on campus</li>
          <li>Secure parking, cameras and electric fencing</li>
        </ul>

        <h2>Gallery</h2>
        <p>Click on a photo to view your selected gallery</p>

        <section className="galleries">
          {galleries.map(({title, slug, images}) => (
            <Link key={slug.current} to={`/gallery/${slug.current}/`} className='gallery-card'>
              <h3>{title}</h3>
              <img src={images[0].asset.url} alt={`${title} featured`} />
            </Link>
          ))}
        </section>
      </StyledOurCampusPage>
    </SubLayout>
  )
}

export const query = graphql`
  query OurCampusPageQuery {
    allSanityGallery {
      nodes {
        title
        slug { current }
        images {
          asset {
            url
          }
        }
      }
    }
  }
`

const StyledOurCampusPage = styled(StyledGeneralPage)`
  /* padding: 4rem; */

  section.galleries {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1.5px;
    margin-top: 1rem;

    a.gallery-card {
      position: relative;
      padding-top: 100%;
      background: rgb(0,0,0);
      background: linear-gradient(15deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 60%);
      overflow: hidden;

      h3 {
        position: absolute;
        bottom: 0;
        color: #f4f4f4;
        margin: 1rem;
        font-size: 1.75rem;
      }

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: transform 0.4s;
        z-index: -1;
      }

      &:hover img {
        transform: scale(1.1);
      }
    }

  }
`

export default OurCampusPage
